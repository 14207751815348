<template>
<div>
  <div class="full-width category-container m-t-36">
    <div class="container">
      <Breadcrumbs :path="path" />
    </div>
  </div>

  <div class="full-width category-container" v-bind:key="childKey" v-if="item">
    <div class="container">
      <div class="col-7 product-images">
        <!--<img :src="'http://shop.altromercato.it/products/' + images[0]" class="main-image" />-->

        <h2 class="neue only-mobi prod-name-mobi">{{$utils.capitalize(item.productName)}}<span v-if="item.masterCode"> - {{item.measure}}</span></h2>

        <div class="obj-marks">
          <div class="marks">
            <!--<img src="@/assets/marks/icona1.png" v-if="item.tags && item.tags.includes('ORGANIC')" v-tooltip="'BIO'" />
            <img src="@/assets/marks/icona2.png" v-if="item.tags && item.tags.includes('VEGAN')" v-tooltip="'VEGAN'" />
            <img src="@/assets/marks/icona3.png" v-if="item.tags && item.tags.includes('GLUTEN_FREE')" v-tooltip="'GLUTEN FREE'" />-->
          </div>

          <img :src="'https://shop.altromercato.it/products/' + images[0] + '?v=2'" class="main-image" v-if="images && images[0]" />
          <img src="https://shop.altromercato.it/products/placeholder.png" class="main-image" v-if="!images" />

          <!--<img :src="'https://shop.altromercato.it/products/' + images[1] + '?v=2'" class="main-image" v-if="$utils.isModa(item.itemNumber) && images && images[1]" />
          <img :src="'https://shop.altromercato.it/products/' + images[2] + '?v=2'" class="main-image" v-if="$utils.isModa(item.itemNumber) && images && images[2]" />-->
        </div>

        <div class="side-images">
          <img
            :src="'https://shop.altromercato.it/products/' + item + '?v=2'"
            v-for="(item, k) in images.slice(1)"
            v-bind:key="k"
            @click="exchange(k)" />
        </div>
      </div>

      <div class="col-5 product-content">

        <h2 class="neue little-name-mobi">{{$utils.capitalize(item.productName)}}<span v-if="item.masterCode"> - {{item.measure}}</span></h2>
        <div class="code neue">COD. {{item.itemNumber}}</div>
        <div class="neue unit" v-if="!item.masterCode">{{item.measure}}</div>

        <router-link
          :to="'/product/' + taglia.itemNumber + '/' + $route.params.slug"
          :class="'neue taglia ' + (taglia.active ? 'active' : '')"
          v-for="(taglia, k) in taglie"
          v-bind:key="k">{{taglia.label}}</router-link>

        <!--<p v-if="item && $utils.isAbbigliamento(item.itemNumber)" class="guida-alle-taglie" @click="openGuida">Guida alle taglie</p>-->

        <div v-if="item && isAlcool" class="alcool-error">
          L'alcool è pericoloso per la salute, consumare con moderazione
        </div>

        <div class="row" v-if="item.stock < 1 || !item.acquistabile">
          <p class="error">Prodotto non disponibile</p>
        </div>

        <div class="row" v-if="item.stock > 0 && item.acquistabile">
          <div class="prod-qty col-4">
            <div class="qty-choose" @click="toggleChooseQty()">
              <span class="number">{{currentQty}}</span>
              <span class="fa fa-chevron-down"></span>

              <div :class="'choose-tent ' + (showTent ? 'open' : '')" v-if="showTent">
                <div @click="setQty(itm)" v-for="(itm, k2) in $utils.getMultipleArray(item)" v-bind:key="k2">{{itm}}</div>
                <div class="nomarg">
                  <input type="number" @click="stopPropoli" @keyup.enter="setQtyCustom" placeholder="Altro" class="pdpdpd" />
                </div>
              </div>
            </div>
          </div>
          <div class="price col-4">
            <span class="cart-discount" v-if="item.sconto > 0">&euro; {{($utils.getProductPrice(item, true).toFixed(2) + "").replace('.', ',')}} <b style="display: inline-block; margin-left: 5px">-{{item.sconto}}%</b></span>
            <span class="current">&euro; {{$utils.getProductPrice(item).toFixed(2)}}</span>
            <span class="online-price">Prezzo online</span>
          </div>
          <div class="col-4">
            <div class="add-to-cart" @click="addToCart(item)">
              aggiungi
              <img src="@/assets/cart-white.png" class="cart-icon" />
              <!--<span class="fa fa-shopping-cart"></span>-->
            </div>
          </div>
        </div>

        <h4>Dettagli regalo</h4>

        <div class="row" v-for="index in qtys" v-bind:key="index">
          <div class="col-6" style="margin-bottom: 10px;">
            <input type="text" placeholder="Email destinatario" />
          </div>
          <div class="col-6" style="margin-bottom: 10px;">
            <input type="text" placeholder="Nome destinatario" />
          </div>
        </div>

        <p class="neue">{{ item.shortDescription }}</p>
        <p class="neue little-shipping">La consegna avviene mediamente entro 5 giorni lavorativi dall'ordine in Italia<br>Spedizione gratuita per ordini superiori ai 59 &euro;</p>

        <ul class="selling-points">
          <li v-for="(sp, k) in item.sellingPoints" v-bind:key="k" class="neue">{{$utils.capitalize(sp)}}</li>
        </ul>

        <p class="description neue">{{ item.extendeDescription }}</p>

        <div class="accordion">
          <div :class="'accordion-step ' + (item.open ? 'open' : '')" v-for="(item, k) in accordion" v-bind:key="k">
            <div class="accordion-header" @click="toggleAccordion(k)">
              {{item.label}}
              <span class="fa fa-chevron-down"></span>
            </div>
            <div class="accordion-content">
              <p v-html="item.content"></p>
            </div>
          </div>
        </div>


        <VueSlickCarousel id="slider" v-bind="settings" v-if="suppliers.length && item">
          <div class="producer" v-for="(item2, k) in suppliers" v-bind:key="k">

            <p class="impact-description" v-if="suppliersEthical.includes(item2.codice)">ON EARTH Ethical Fashion</p>
            <p class="impact-description" v-if="suppliersUpcycling.includes(item2.codice)">ON EARTH Upcycling Creation</p>

            <h4 class="impact-title" v-if="suppliersEthical.includes(item2.codice) || suppliersUpcycling.includes(item2.codice)">created by</h4>

            <p class="impact-description" v-if="!suppliersEthical.includes(item2.codice) && !suppliersUpcycling.includes(item2.codice)">L'impatto sull'uomo e sul pianeta</p>
            <h4 class="impact-title" v-if="!suppliersEthical.includes(item2.codice) && !suppliersUpcycling.includes(item2.codice)">LUOGO DI COLTIVAZIONE E PRODUZIONE</h4>

            <div :id="'supplier-map-' + k" class="supplier-map" v-if="!badSuppliers.includes(item2.codice)"></div>

            <img :src="require('@/assets/' + item2.codice + '.png')" v-if="badSuppliers.includes(item2.codice)" class="logo-bad" />

            <div class="row">
              <div class="col-6 supplier-name"><a :href="item2.URL ? item2.URL : 'https://www.altromercato.it/' + item2.titolo" target="_BLANK">{{item2.titolo}}</a></div>
              <div class="col-6 align-right neue">{{item2.paese}}</div>
            </div>
          </div>
        </VueSlickCarousel>

        <!--<img src="@/assets/reso_product.jpg?v=1" v-if="$utils.isModa(item.itemNumber)" style="margin-top: 40px; max-width: 100%;" />-->

      </div>
    </div>
  </div>

  <div class="full-width spacer"></div>

  <ProductSlider v-if="item" :code="item.productCategory" :exclude="[$route.params.code]" :related="item.related" />

  <modal name="guida">
    <div class="modal-spacer">
      <h3>Guida alle taglie</h3>

      <table class="table-guida">
        <tr>
          <th>IT</th>
          <th>INT</th>
          <th style="max-width: 120px">MISURA IL TUO BUSTO POSIZIONANDO IL METRO SOTTO LE ASCELLE</th>
          <th>VITA</th>
          <th>FIANCHI</th>
        </tr>
        <tr>
          <td>40</td>
          <td>XS</td>
          <td>80-83 cm</td>
          <td>66-68 cm</td>
          <td>89-92 cm</td>
        </tr>
        <tr>
          <td>42</td>
          <td>S</td>
          <td>84-88 cm</td>
          <td>69-71 cm</td>
          <td>93-97 cm</td>
        </tr>
        <tr>
          <td>44</td>
          <td>M</td>
          <td>88-91 cm</td>
          <td>72-75 cm</td>
          <td>97-99 cm</td>
        </tr>
        <tr>
          <td>46</td>
          <td>L</td>
          <td>92-95 cm</td>
          <td>76-79 cm</td>
          <td>100-102 cm</td>
        </tr>
        <tr>
          <td>48</td>
          <td>XL</td>
          <td>96-99 cm</td>
          <td>80-84 cm</td>
          <td>103-106 cm</td>
        </tr>
      </table>
    </div>
  </modal>
</div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import ProductSlider from '@/components/ProductSlider'
//import axios from 'axios'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Product',
  data: function () {
    return {
      path: [
        { label: 'Home', path: '/' },
        { label: 'Idee Regalo', path: '/category/' },
        { label: 'Gift Card 20 euro', path: '/product/zucchero-di-canna' },
      ],

      badSuppliers: ['M361', 'AQ', 'EQ', '9816334', '9815390'],
      suppliersEthical: ['M361', 'AQ', 'EQ', '9815390'],
      suppliersUpcycling: ['9816334'],

      accordion: [],
      item: false,
      suppliers: [],
      images: [],
      currentQty: 1,
      showTent: false,
      taglie: [],
      childKey: 1,
      settings: {
        "arrows": true,
        "dots": true,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "swipeToSlide": false,
        "touchThreshold": 2
      }
    }
  },
  components: {
    Breadcrumbs,
    ProductSlider,
    VueSlickCarousel
  },

  watch: {
    $route() {
      this.initPage()
      this.childKey++
    }
  },

  async created () {
    await this.initPage()
  },

  computed: {
    qtys: function () {
      let res = []
      for (let index = 1; index <= this.currentQty; index++) {
        res.push(index)
      }

      return res
    },
    isAlcool: function () {
      let alc = false

      if (!this.item.categories) {
        return alc
      }

      this.item.categories.forEach((c) => {
        if (c.categoryCode == 'EC81500') {
          alc = true
        }
      })

      return alc
    }
  },

  methods: {
    toggleAccordion(id) {
      this.accordion[id].open = !this.accordion[id].open
    },

    stopPropoli (evt) {
      evt.stopPropagation()
    },

    setQtyCustom (evt) {
      let qty = parseInt(evt.target.value)
      if(isNaN(qty) || !isFinite(qty) || (qty < 1)) {
        qty = 1
      }

      if (qty > this.item.stock) {
        qty = this.item.stock
      }

      let multiplo = parseInt(this.item.multiplo)

      if (multiplo > 1 && (qty % multiplo != 0)) {
        qty = qty - (qty % multiplo) + multiplo
      }

      this.currentQty = qty
      this.showTent = !this.showTent
      evt.target.value = ''
    },

    async initPage () {
      try {
        this.taglie = []
        this.images = ['00004388_1.jpg']
        this.accordion = []

        //let allImages = await axios.get('https://shop.altromercato.it/products/imgs.php')
        this.item = {
    "visible": true,
    "vetrina": false,
    "primary": 1,
    "sconto": 10,
    "multiplo": 1,
    "acquistabile": true,
    "categories": [
        {
            "productCategoryHierarchyName": "ALBERO E-COMMERCE",
            "categoryName": "EC81000 Caffè e orzo",
            "categoryCode": "EC81000",
            "depth": 4,
            "language": "IT",
            "parentProductCategoryCode": "EC71000"
        },
        {
            "productCategoryHierarchyName": "ALBERO E-COMMERCE",
            "categoryName": "EC71000 CAFFÈ, TE TISANE",
            "categoryCode": "EC71000",
            "depth": 3,
            "language": "IT",
            "parentProductCategoryCode": "EC61000"
        },
        {
            "productCategoryHierarchyName": "ALBERO E-COMMERCE",
            "categoryName": "EC61000 ALIMENTARI",
            "categoryCode": "EC61000",
            "depth": 2,
            "language": "IT",
            "parentProductCategoryCode": "EC50000"
        },
        {
            "productCategoryHierarchyName": "ALBERO E-COMMERCE",
            "categoryName": "EC50000 ALBERO E-COMMERCE",
            "categoryCode": "EC50000",
            "depth": 1,
            "language": "IT",
            "parentProductCategoryCode": null
        },
        {
            "visible": true,
            "productCategoryHierarchyName": "ALBERO E-COMMERCE",
            "categoryName": "EC67000 IDEE REGALO",
            "categoryCode": "EC67000",
            "depth": 2,
            "language": "IT",
            "parentProductCategoryCode": "EC50000"
        }
    ],
    "related": [],
    "productPrice": 20,
    "stock": 3904,
    "itemNumber": "00004388",
    "productName": "Gift Card 20€",
    "shortDescription": "",
    "extendeDescription": "",
    "measurementUnit": null,
    "measure": "",
    "size": null,
    "productCategory": "EC81000",
    "brand": "Altromercato",
    "masterCode": "",
    "mainColour": "",
    "legalName": "",
    "pricePerUnit": null,
    "vatRate": 22,
    "defaultOrderSettings": 1,
    "sellingPoints": [],
    "fairTradeSuppliers": [
        "9815556",
        " 9812413",
        " 9812577"
    ],
    "tags": [
        "ORGANIC",
        "VEGAN",
        "GLUTEN_FREE"
    ],
    "extendedData": [
        "Inserire nel carrello e godere dello sconto",
        "",
        "",
        ""
    ],
    "nutritionalFacts": [],
    "images": [
        "00004388_1.jpg",
    ],
    "packagingDisposal": "",
    "producedBy": "",
    "visibile": true
}
        this.currentQty = this.item.multiplo ? this.item.multiplo : 1
        /*this.path = [{
          label: 'Home',
          path: '/'
        }].concat(this.$utils.getCategoryPath(this.item.productCategory).reverse()).concat([{
          label: this.$utils.capitalize(this.item.productName),
          path: ''
        }])*/

        /*allImages.data.forEach((item) => {

          if (this.item.masterCode) {
            if (item.indexOf(this.item.masterCode + '_') == 0) {
              this.images.push(item)
            }
          } else {
            if (item.indexOf(this.$route.params.code + '_') == 0) {
              this.images.push(item)
            }
          }
        })*/

        this.taglie = [
          { label: '20€', active: true, itemNumber: '00004388' },
          { label: '50€', active: false, itemNumber: '00004389' },
          { label: '100€', active: false, itemNumber: '00004390' },
          { label: '150€', active: false, itemNumber: '00004391' },
          { label: '200€', active: false, itemNumber: '00004392' },
          //{ label: '', active: false, itemNumber: '00004392' },
        ]

        /*if (this.item.fairTradeSuppliers && this.item.fairTradeSuppliers[0]) {
          this.suppliers = this.$utils.getSuppliers(this.item.fairTradeSuppliers)
        }*/

        this.suppliers = []

        if (this.item.legalName && this.item.legalName.length) {
          this.accordion.push({
            content: this.item.legalName,
            label: 'Denominazione legale',
            open: false
          })
        }

        if (this.item.nutritionalFacts.length) {
          let html = '<table class="table-nutri">'

          html += '<tr class="header-nutri"><td>Elemento</td><td>Per 100g</td>'

          if (this.item.nutritionalFacts[0].dailyValueForServingSize) {
            html += '<td>%AR per porzione</td>'
          }

          if (this.item.nutritionalFacts[0].amountForServingSize) {
            html += '<td>Quantità per porzione'
            if (this.item.nutritionalFacts[0].servingSize) {
              html += '<br>' + this.item.nutritionalFacts[0].servingSize
            }
            html += '</td>'
          }

          html += '</tr>'

          for (let index in this.item.nutritionalFacts) {
            html += '<tr><td>' + this.item.nutritionalFacts[index].nutritionalValue + '</td><td>' + this.item.nutritionalFacts[index].amountFor100 + '</td>'

            if (this.item.nutritionalFacts[0].dailyValueForServingSize) {
              html += '<td>' + this.item.nutritionalFacts[index].dailyValueForServingSize + '%</td>'
            }

            if (this.item.nutritionalFacts[0].amountForServingSize) {
              html += '<td>' + this.item.nutritionalFacts[index].amountForServingSize + '</td>'
            }

            html += '</tr>'
          }
          html += '</table><p>**AR: Assunzione di riferimento giornaliera di un adulto medio (8400Kj/2000Kcal)</p>'
          this.accordion.push({
            content: html,
            label: 'Valori nutrizionali',
            open: false,
            facts: this.item.nutritionalFacts.length
          })
        }

        let k = 0
        let titles = ['Modalità d\'uso', 'Lista ingredienti', '% di ingredienti di commercio equo', 'Allergeni']

        this.item.extendedData.forEach((ed) => {

          if (ed.length && ed != '0' && k != 2) {
            this.accordion.push({
              open: false,
              label: titles[k],
              content: ed
            })
          }

          k++
        })

        if (this.item.producedBy && this.item.producedBy.length) {
          this.accordion.push({
            content: this.item.producedBy,
            label: 'Prodotto da',
            open: false
          })
        }

        if (this.item.packagingDisposal && this.item.packagingDisposal.length) {
          this.accordion.push({
            content: this.item.packagingDisposal,
            label: 'Smaltimento imballaggio',
            open: false
          })
        }

        // Set google maps for supplier
        setTimeout(() => {

          let index = 0

          this.suppliers.forEach((suppl) => {
            let latLng = {
              lat: parseFloat(suppl.latitudine),
              lng: parseFloat(suppl.longitudine)
            }

            // eslint-disable-next-line no-use-before-define
            if (document.getElementById("supplier-map-" + index)) {
              let map = new window.google.maps.Map(document.getElementById("supplier-map-" + index), {
                center: latLng,
                zoom: 4,
                styles: this.$config.mapStyles,
                zoomControl: false,
                disableDefaultUI: true
              });

              new window.google.maps.Marker({
                position: latLng,
                map,
                icon: '/products/marker.png',
                title: suppl.nome_produttore,
              });
            }

            index++
          })

        }, 500)

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          'event': 'productClick',
          'ecommerce': {
            'click': {
              'actionField': {'list': 'Product detail page'},      // Optional list property.
              'products': [{
                name: this.item.productName,
                id: this.item.itemNumber,
                price: this.item.productPrice,
                brand: this.item.brand,
                category: this.item.categories[this.item.categories.length - 1].categoryName,
                variant: this.item.fairTradeSuppliers ? this.item.fairTradeSuppliers[0] : ''
               }]
             }
           }
        });

        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          'ecommerce': {
            'detail': {
              'actionField': {'list': 'Apparel Gallery'},
              'products': [{
                name: this.item.productName,
                id: this.item.itemNumber,
                price: this.item.productPrice,
                brand: this.item.brand,
                category: this.item.categories[this.item.categories.length - 1].categoryName,
                variant: this.item.fairTradeSuppliers ? this.item.fairTradeSuppliers[0] : ''
               }]
             }
           }
        });

      } catch (e) {
        console.log(e)
      }
    },

    exchange (id) {
      let tmp = this.images[0]
      this.images[0] = this.images[id + 1]
      this.images[id + 1] = tmp
      this.$forceUpdate()
    },

    addToCart(item) {
      this.$toast.open('Articolo aggiunto al carrello')
      this.$store.commit('addToCart', {
        item,
        qty: this.currentQty
      })
    },

    toggleChooseQty () {

      if (!this.showTent) {

        setTimeout(() => {
          document.querySelector('body').addEventListener('click', () => {
            if (this.showTent) {
              this.showTent = false
            }
          }, { once: true })
        }, 300)

        this.showTent = true
      } else {
        this.showTent = false
      }
    },

    setQty (qty) {
      this.currentQty = qty
    },

    openGuida () {
      this.$modal.show('guida')
    }
  }
}
</script>

<style >
.slick-prev, .slick-next {
  top: 60%;
}
.slick-prev{
  left: 12px;
  z-index: 1;
}
.slick-next {
  right: 10px;
  z-index: 1;
}
.main-image {
  width: 100%;
  border: 1px solid #eee;
}

.product-content {
  padding-left: 30px;
}

.product-content h2 {
  font-size: 33px;
  font-weight: 600;
  max-width: 375px;
  margin-bottom: 20px;
}

.product-content .code {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}

.unit {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 65px;
}

.side-images img {
  cursor: pointer;
}

.prod-name-mobi {
  font-size: 28px;
  text-align: center;
  margin-bottom: 15px;
  padding: 0 10px;
}

.little-name-mobi {
  font-size: 18px !important;
  margin: 10px 0 5px 0;
}

.product-content {
  padding: 0 10px;
}

.selling-points {
  margin: 0;
  padding-left: 15px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 35px;
}

.obj-marks {
  position: relative;
}

.obj-marks .marks {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
}

.obj-marks .marks img {
  width: 40px;
}

.cart-discount {
  display: inline-block;
  padding: 2px 5px;
  color: #fff;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 3px;
  background-color: #f09049;
}

.little-shipping {
  font-size: 12px;
  letter-spacing: 0.4px;
}

.supplier-map {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
}

.supplier-name {
  text-transform: uppercase;
  font-weight: bold;
  font-family: neue-haas-grotesk-display, sans-serif;
}

.taglia {
  display: inline-block;
  border: 1px solid #ccc;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  /*padding: 0 10px;*/
  margin-right: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
  border-radius: 50%;
  color: #ccc;
}

.taglia:hover, .taglia.active {
  border-color: #555;
  color: #555;
}

.pdpdpd {
  width: 100%;
  height: 100%;
  border: 0;
}

.nomarg {
  margin: 0 !important;
  padding: 0 !important;
}

.guida-alle-taglie {
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 30px;
  display: inline-block;
  margin-left: 40px;
}

.modal-spacer {
  width: 100%;
  height: 100%;
  padding: 15px;
}

.table-guida {
  width: 100%;
  font-size: 14px;
}

.table-guida th {
  text-align: left;
  padding-bottom: 10px;
}

.table-guida td {
  padding-bottom: 5px;
}

.impact-description {
  font-size: 13px;
  margin-bottom: 2px;
  margin-top: 40px;
}

.impact-title {
  margin: 7px 0 15px 0;
}

.logo-bad {
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
  object-fit: contain;
}

@media (max-width: 680px) {

}
</style>
